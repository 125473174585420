.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-info p {
  margin-bottom: 0;
  margin-top: 0;
}

.donate-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.w100 {
  width: 100%;
}

.headshot-image {
  width: 200px;
}

.video {
  margin: 5px 25px 5px 25px;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
}

.responsive-flex {
  display: flex;
  flex-direction: row;
}

.youtube-link {
  margin: auto;
  text-align: center;
  padding-bottom: 50px;
}

.shareholders {
  display: flex;
  flex-wrap: wrap;
}

.update-item {
  padding: 5px !important;
}

.donate-button {
  font-size: x-large;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.see-more {
  display: flex;
  cursor: pointer;
}

.see-more a {
  margin: auto;
  margin-top: 30px;
}

.teamCard {
  transition: 0.5s;
  height: 340px;
  width: 250px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px;
}

.construction-section {
  padding-bottom: 10px;
}

.teamCard:hover {
  transition: 0.3s;
  box-shadow: 0px 0px 10px  #888888;
}

.teamCard img {
  height: 200px;
  width: auto;
}

.img-icon {
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {

  .shareholders {
    width: 100%;
  }

  .see-more {
    width: 100%;
  }

  .see-more a {
    margin-top: 0px;
  }

  .responsive-flex {
    flex-direction: column;
  }

}